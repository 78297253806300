<template>
  <span>
    <v-skeleton-loader v-if="loading" type="chip"></v-skeleton-loader>
    <span v-else>{{ displayName }}</span>
  </span>

</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "UserBadge",
  props: {
    id: Number,
    prefix: String,
  },
  data() {
    return {
      loading: true,
      user: null,
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      if (this.id) {
        axios.get("/api/" + this.prefix + "/user/" + this.id).then((response) => {
          this.user = response.data;
          this.loading = false
        });
      }
    }
  },
  computed: {
    displayName() {
      return this.user.firstName + " " + this.user.lastName
    }
  },
  watch: {
    id() {
      this.fetch()
    }
  }
};
</script>

<style scoped>
</style>
