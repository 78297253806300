<template>
  <UserBadge :id="id" prefix="admin" ></UserBadge>
</template>

<script>
import UserBadge from "@/components/general/UserBadge.vue";

export default {
  name: "AdminUserBadge",
  components: {UserBadge},
  props: {
    id: Number,
  },
}
</script>

<style scoped>

</style>