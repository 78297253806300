<template>
  <v-container class="content">
    <v-row no-gutters>
      <v-col align="center">
        <h1 class="mb-8">Order {{ order.id }}</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col align="right">
        <v-btn v-if="order.status === 'CREATED'" color="light-green darken-2" dark @click="changeStatus('FULFILLED')" :loading="loading">
          Fulfill
          <v-icon right> mdi-truck-fast-outline </v-icon>
        </v-btn>
        <v-btn v-else color="amber darken-2"  @click="changeStatus('CREATED')" :loading="loading">
          <v-icon left> mdi-cash-refund </v-icon>
          Unfulfill
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>Info</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                Created:  {{ new Date(order.createdDate).toLocaleString() }}
              </v-col>
              <v-col cols="4">
                Created By: <admin-user-badge :id="order.createdBy"></admin-user-badge>
              </v-col>
              <v-col cols="4">
                Status: {{order.status}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                Company: {{ order.company.name }}
              </v-col>
              <v-col cols="4">
                Address: {{ order.company.address }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>Items</v-card-title>
          <v-card-text>
            <order-items v-model="order.orderItems" mode="view"></order-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";
import OrderItems from "@/components/general/OrderItems.vue";
import AdminUserBadge from "@/components/admin/AdminUserBadge.vue";

export default {
  components: {AdminUserBadge, OrderItems},
  name: "AdminOrderView",
  metaInfo: {
    title: "Order View",
  },
  data() {
    return {
      order: {},
      id: null,
      loading: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get("/api/admin/order/" + this.id).then((response) => {
        this.order = response.data;
      });
    },
    changeStatus(status) {
      this.loading = true;
      this.order.status = status

      axios
        .put("/api/admin/order/" + this.order.id, this.order)
        .then(() => {
          this.$store.dispatch('banner/open', {text: 'Order ' + this.order.id + ' Updated!'})
          this.fetchData()
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
